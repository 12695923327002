import React from 'react';
import { Container } from 'react-bootstrap';
function ForgotPasswordSuccess() {
    return (
        <Container className="vh-100">
            <div className="jumbotron d-flex align-items-center justify-content-center h-100">
                <div>
                <h2>Forgot password request has been registered.</h2>
                <p>You will receive an email with instructions to reset your password.</p>
                </div>
            </div>
        </Container>
    )
}
export default ForgotPasswordSuccess;