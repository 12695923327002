import React from 'react';
import { Container } from 'react-bootstrap';
function RegistrationSuccess() {
    return (
        <Container className="vh-100">
            <div className="jumbotron d-flex align-items-center justify-content-center h-100">
                <div>
                    <h2>Great! you have done it.</h2>
                    <p>Please login to start enjoying the app.</p>
                </div>
            </div>
        </Container>
    )
}
export default RegistrationSuccess;