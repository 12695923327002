import React from 'react';
import HomePage from "./components/HomePage";
import Login from "./components/Login";
import Register from "./components/Register";
import AuthService from './service/AuthService.js';
import RegistrationSuccess from './components/RegistrationSuccess';
import Logout from './components/Logout';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordSuccess from './components/ForgotPasswordSuccess';
import {ResetPassword, ResetPasswordSuccess} from './components/ResetPassword';
import { ChangePassword } from './components/ChangePassword';

const authService = new AuthService();
const routes = {
	 "/login": () => (user) => <Login service={authService}/>,
	 "/register": () => (user) => <Register service = {authService} />,
	 "/register-success": () => (user) => <RegistrationSuccess />,
	 "/": () => (user) => <HomePage />,
	 "/logout": ()=> (user) => <Logout />,
	 "/forgot-password": ()=> (user) => <ForgotPassword service={authService} />,
	 "/forgot-password-success": ()=> (user) => <ForgotPasswordSuccess />,
	 "/reset-password": ()=> (user) => <ResetPassword service={authService} />,
	 "/reset-password-success": ()=> (user) => <ResetPasswordSuccess />,
	 "/change-password": ()=> (user) => <ChangePassword service={authService} user={user} />,
}

export default routes;
