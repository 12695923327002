import React, {useEffect} from 'react';
import {useQueryParams} from 'hookrouter'

export default function  Logout() {
    const [queryParams] = useQueryParams();
    const redirectURL = queryParams.redirect_url;
    useEffect(() => {
        window.authSession.logout();
        window.setTimeout(() => {
            if(redirectURL) {
                window.location.href = redirectURL;
            } else {
                window.location.href = "/";
            }
        }, 100);
    }, [redirectURL]);
    return(<></>)
}