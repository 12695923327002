import { navigate } from 'hookrouter';
import config from '../config';

export function AuthService() {
    var loginSuccessListener;
    var loginErrorListener;

    function makePostCall(url, data) {
        var promise = new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json())
                .then(response => resolve(response))
                .catch(err => reject(err));
        });
        return promise;
    }
    this.addLoginListeners = (successListener, errorListener) => {
        loginSuccessListener = successListener;
        loginErrorListener = errorListener;
    }
    this.removeListeners = () => {
        loginErrorListener = null;
        loginSuccessListener = null;
    }
    this.forgotPassword = (email) => {
        return makePostCall(
            config.FORGOT_PASSWORD_URL,
            { "email": email }
        );
    }
    this.resetPassword =(token, password) => {
        console.log(config.RESET_PASSWORD_URL);
        return makePostCall(
            config.RESET_PASSWORD_URL,
            {
                "reset_token": token,
                "new_password": password
            }
        );
    }
    this.login = (email, password) => {
        console.log("Authenticating user with email: " + email + " and password: xxxx and login api url: " + process.env.REACT_APP_AUTH_LOGIN_API_URL);
        fetch(process.env.REACT_APP_AUTH_LOGIN_API_URL, {
            method: 'POST',
            body: JSON.stringify({ "email": email, "password": password }),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            return res.json();
        })
            .then(response => processLoginResponse(response))
            .catch(err => console.log("Error: " + err));
    }
    const processLoginResponse = (response, loginCallback) => {
        console.log("Login response: " + JSON.stringify(response));
        window.authSession.addLoginListeners(loginSuccessListener, loginErrorListener);
        checkAndCreate(response);
    }
    const checkAndCreate = (response) => {
        if (response.message === "Login Successful") {
            window.authSession.createSession(response);
        }
        if (response.error) {
            console.log("Error login response: " + JSON.stringify(response.error))
            loginErrorListener(response.error.user_authentication);
        }

    }

    this.changePassword = (data, accessToken, handleChangePasswordResponse) => {
        const url = process.env.REACT_APP_AUTH_API_HOST + "/auth/change-password";
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json', Authorization: 'barer ' + accessToken}
        })
        .then(res => res.json())
            .then(response => handleChangePasswordResponse(response, null))
            .catch(err => handleChangePasswordResponse(null, err));
    }

    this.registerUser = (email, password) => {
        console.log("Registering user with email: " + email + " and password: xxxxxx");
        fetch(process.env.REACT_APP_AUTH_REGISTER_API_URL, {
            method: 'POST',
            body: JSON.stringify({ "email": email, "password": password, "email_verified": "1" }),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            return res.json();
        })
            .then(response => showRegistrationSuccessPage(response))
            .catch(err => console.log("Error: " + err));
    }
    const showRegistrationSuccessPage = (response) => {
        console.log("response: " + JSON.stringify(response))
        navigate('/register-success');
    }
}
export default AuthService;