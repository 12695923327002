const config = {
    SESSION_JS_URL: process.env.REACT_APP_SESSION_JS_URL,
    FORGOT_PASSWORD_URL: process.env.REACT_APP_AUTH_FORGOT_PASSWORD_API_URL,
    RESET_PASSWORD_URL: process.env.REACT_APP_AUTH_RESET_PASSWORD_API_URL,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    auth: {
        API_HOST: process.env.REACT_APP_AUTH_API_HOST,
        AUTH_LOGIN_API_URL: process.env.REACT_APP_AUTH_LOGIN_API_URL,
    }
}
export default config;