import React from 'react';
import { Container } from 'react-bootstrap';
function HomePage() {
	return (
		<Container className="vh-100">
			<div className="jumbotron d-flex align-items-center justify-content-center h-100">
				<h2>Welcome to central authentication service</h2>
			</div>
		</Container>
	)
}

export default HomePage;
