import React from 'react';
import config from '../config';
import { Container, Form, Button } from 'react-bootstrap';
import { A, useQueryParams } from 'hookrouter';
import {useInputFocus} from 'appshome-react';

function Login(props) {
	const errorMessage = "";
	const [queryParams] = useQueryParams();
	const emailRef = useInputFocus();
	const returnUrl = ('redirect_url' in queryParams) ? queryParams.redirect_url : window.location.protocol + '//' + window.location.host;
	console.log("Query returnUrl: " + returnUrl);
	const errorUrl = window.location.href
	return (
		<Container className="vh-100 mt-5">
			<div className="d-flex justify-content-center h-100">
				<Form action = {config.auth.AUTH_LOGIN_API_URL} method= "POST" >
					<Form.Text><h2>Login</h2></Form.Text>
					<Form.Group>
						<Form.Text>{errorMessage}</Form.Text>
					</Form.Group>
					<Form.Group controlId="email">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" required ref = {emailRef} name="email" />
					</Form.Group>
					<Form.Group controlId="client_id">
					<Form.Control type="hidden" value = {config.CLIENT_ID} name="client_id" />
					</Form.Group>
					<Form.Group controlId="return_url">
					<Form.Control type="hidden" value = {returnUrl} name="return_url" />
					</Form.Group>
					<Form.Group controlId="error_url">
					<Form.Control type="hidden" value = {errorUrl} name="error_url" />
					</Form.Group>

					<Form.Group controlId="password">
						<Form.Label>Password</Form.Label>
						<Form.Control type="password" placeholder="Password" required name="password" />
					</Form.Group>

					<Form.Text><A href="/forgot-password">Forgot password?</A></Form.Text>
					<Button className="btn-block" variant="primary" type="submit" >
						Login
				</Button>
				</Form>
			</div>
		</Container>
	);
}
export default Login;

