import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useQueryParams } from 'hookrouter';
import { useInputFocus } from 'appshome-react';

export function ChangePassword(props) {
	const [changePassword, setChangePassword] = useState({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
	const [response, setResponse] = useState({ errorMessage: "", status: "" })
	const [queryParams] = useQueryParams();
	const oldPasswordRef = useInputFocus();
	console.log("Query params " + JSON.stringify(queryParams));
	const handleChangePasswordResponse = (response, error) => {
		if (response) {
			if (response.status === "Success") {
				setResponse({ errorMessage: "", status: response.status })
			} else {
				setResponse({errorMessage: response.status, status: ""})
			}
		}else {
			setResponse({errorMessage: error, status: ""})
		}
	}

	const handleChangePassword = () => {
		props.service.changePassword(changePassword, props.user.accessToken, handleChangePasswordResponse)
	}
	return (
		<Container className="vh-100 mt-5">
			<div className="d-flex justify-content-center h-100">
				{response.status === "Success" &&
				<div>
				<h3>Your password has been changed successfully.</h3>
				{queryParams.redirect_url && <a href={queryParams.redirect_url}>Return back to app.</a>}
				</div>
				}
				{response.status === "" && <Form>
					<Form.Text><h2>Change password</h2></Form.Text>
					<Form.Group>
						<Form.Text>{response.errorMessage}</Form.Text>
					</Form.Group>
					<Form.Group controlId="oldPassword">
						<Form.Label>Old password:</Form.Label>
						<Form.Control type="password" placeholder="Old password" onChange={(e) => setChangePassword({ ...changePassword, oldPassword: e.target.value })} required ref={oldPasswordRef} />
					</Form.Group>

					<Form.Group controlId="newPassword">
						<Form.Label>New password:</Form.Label>
						<Form.Control type="password" placeholder="New password" onChange={(e) => setChangePassword({ ...changePassword, newPassword: e.target.value })} required />
					</Form.Group>

					<Form.Group controlId="confirmNewPassword">
						<Form.Label>Confirm new password:</Form.Label>
						<Form.Control type="password" placeholder="Confirm new password" onChange={(e) => setChangePassword({ ...changePassword, confirmNewPassword: e.target.value })} required />
					</Form.Group>
					<Button className="btn-block" variant="primary" type="button" onClick={() => handleChangePassword()} >
						Change
				</Button>
				</Form>}
			</div>
		</Container>
	);
}
