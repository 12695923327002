import React, {useState} from 'react';
import { Form, Container, Button } from 'react-bootstrap';
import { navigate, useQueryParams } from 'hookrouter';

export function ResetPassword(props) {
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [queryParams] = useQueryParams();
    console.log(queryParams.reset_token)
    const handleResetPassword = () => {
        props.service.resetPassword(queryParams.token, password)
        .then(res => {
            if(res.status === "Success") {
                navigate("/reset-password-success");
            } else {
                setErrorMessage(res.message);
            }
        })
        .catch(err => console.log(err));
    }
    return (
        <Container className="vh-100 mt-5">
            <div className="d-flex justify-content-center h-100">
                <Form>
                    <Form.Text><h2>Reset your password</h2></Form.Text>
                    <Form.Group>
                        <Form.Text>{errorMessage}</Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} required />
                    </Form.Group>
                    <Button className="btn-block" variant="primary" type="button" onClick={() => handleResetPassword()} >
                        Reset password
                    </Button>
                </Form>
            </div>
        </Container>
    );
}
export function ResetPasswordSuccess() {
    return (
        <Container className="vh-100">
            <div className="jumbotron d-flex align-items-center justify-content-center h-100">
                <div>
                    <h2>Password reset succeeded.</h2>
                    <p>Please login to start enjoying the app.</p>
                </div>
            </div>
        </Container>
    )
}
