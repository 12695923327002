import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import {useInputFocus} from 'appshome-react';

function Register(props) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const emailRef = useInputFocus();
	return (
		<Container className="vh-100 mt-5">
			<div className="d-flex justify-content-center h-100">
				<Form>
					<Form.Text><h2>Register</h2></Form.Text>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} ref = {emailRef} />
						<Form.Text className="text-muted">
							We'll never share your email with anyone else.
	      </Form.Text>
					</Form.Group>

					<Form.Group controlId="formBasicPassword">
						<Form.Label>Password</Form.Label>
						<Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
					</Form.Group>
					<Button className="btn-block" variant="primary" onClick={() => props.service.registerUser(email, password)}>
						Register
	    </Button>
				</Form>
			</div>
		</Container>
	);
}

export default Register;
