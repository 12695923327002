import React from 'react';
import { A, useRoutes } from 'hookrouter';
import routes from './router';
import { Header, MySpinner, NotFound, useAuthentication } from 'appshome-react';
import config from './config';
import { Nav } from 'react-bootstrap';

export default function App() {
	const routeResult = useRoutes(routes);
	const { isAuthenticated, isAuthenticating, authCode, accessToken, userId, userEmail} = useAuthentication(config.SESSION_JS_URL, config.CLIENT_ID);
	const user = {accessToken: accessToken, userId: userId, userEmail: userEmail};
	console.log(`Value of isAuthenticated is: ${isAuthenticated}, value of authCode is: ${authCode}, value of isAuthenticating is: ${isAuthenticating} and value of user is: ${JSON.stringify(user)}.`);
	//const [user, setUser] = useState(null);
	// useEffect(() => {
	// 	console.log("Inside useEffect");
	// 	processAuthCode(isAuthenticated, authCode, setUser);
	// }, [isAuthenticated, authCode]);
	if (isAuthenticating) {
		return (<MySpinner />);
	} else {
		return (
			<>
				<Header navItems={[]} isAuthenticated={isAuthenticated} customAuthOptions={AuthOptions}>
					<h1 className="h3">Auth</h1>
				</Header>
				{routeResult(user) || <NotFound />}
			</>
		);
	}
}
function AuthOptions(props) {
	if (props.isAuthenticated) {
		return (
			[<Nav.Item><A href="/change-password" className="nav-link">Change password</A></Nav.Item>,
			<Nav.Item><A href="/logout" className="nav-link">Logout</A></Nav.Item>]
		);
	} else {
		return (
			[<Nav.Item><A href="/login" className="nav-link">Login</A></Nav.Item>,
			<Nav.Item><A href="/register" className="nav-link">Register</A></Nav.Item>]
		)
	}
}
