import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import {navigate} from 'hookrouter';
import {useInputFocus} from 'appshome-react';

function ForgotPassword(props) {
    const [email, setEmail] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const emailRef = useInputFocus();
	const handleForgotPassword = () => {
        props.service.forgotPassword(email)
        .then(res => {
            console.log(res);
            if (res.status === "Success") {
                navigate("/forgot-password-success")
            } else {
                setErrorMessage(res.message);
            }
        })
        .catch(err => console.log(err));
	};
	return (
		<Container className="vh-100 mt-5">
			<div className="d-flex justify-content-center h-100">
				<Form>
				<Form.Text><h2>Forgot password</h2></Form.Text>
                <Form.Text>Enter your email address to start the reset process.</Form.Text>
					<Form.Group>
						<Form.Text>{errorMessage}</Form.Text>
					</Form.Group>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} required ref = {emailRef} />
					</Form.Group>

					<Button className="btn-block" variant="primary" type="button" onClick={() => handleForgotPassword()} >
						Submit
				</Button>
				</Form>
			</div>
		</Container>
	);
}
export default ForgotPassword;
